.expozy-logo {
    max-width: 100px;
}

.hero-banner-five .hero-heading span, .fancy-text-block-twentyOne .text-wrapper p span, .title-style-six h2 span, .title-style-six h2 span {
    color: #E52711 !important;
}

::-webkit-scrollbar-thumb {
    background: #E52711 !important;
}

.f-none-m-auto {
    float: none;
    margin: 0 auto;
}

.fancy-text-block-twentyOne .text-wrapper p {
    font-size: 40px;
}

.accordion-style-two .card {
    background: transparent !important;
}

body .theme-btn-eleven {
    font-family: "gordita";
    font-weight: 500;
    font-size: 18px;
    line-height: 48px;
    border: 2px solid #000;
    border-radius: 30px;
    color: #000;
    padding: 0 40px;
    transition: all .3s ease-in-out;
}

.footer-logo {
    max-width: 150px;
    width: 100%;
}

ul.list-item-one {
    list-style-type: circle;
}

body .theme-btn-twelve {
    font-family: "gordita";
    font-size: 17px;
    line-height: 48px;
    background: #E52711 !important;
    border: 2px solid #E52711 !important;
    border-radius: 30px;
    color: #fff;
    padding: 0 32px;
    transition: all 0.3s ease-in-out;
}

body .theme-btn-twelve:hover {
    background: transparent !important;
    color: #E52711 !important;
}

.react-tabs__tab--selected button {
    background: #000;
    color: #fff;
}

.po-control-one button {
    width:100%;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link {
    color:#000;
}

.inner-page-white-nav-items .theme-menu-seven.fixed .navbar-nav .nav-item .nav-link {
    color:#fff;
}

.g-3px {
    gap:3px;
}

.related-article-post-meta-padding-bottom {
    padding: 35px 40px 80px !important;
}


.theme-menu-seven .navbar-nav .nav-item .nav-link .login-icon-nav {
    fill: #fff;
    max-width:40px;
    width: 100%;
    height:auto;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link {
    color:#000;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link .login-icon-nav {
    max-width:40px;
    width: 100%;
    height:auto;
    fill: #fff;
}

.inner-page-white-nav-items .theme-menu-seven .navbar-nav .nav-item .nav-link .login-icon-nav {
    max-width:40px;
    width: 100%;
    height:auto;
    fill: #000;
}

.inner-page-white-nav-items .theme-menu-seven.fixed .navbar-nav .nav-item .nav-link {
    color:#fff;
}

.inner-page-white-nav-items .theme-menu-seven.fixed .navbar-nav .nav-item .nav-link .login-icon-nav {
    max-width: 40px;
    width:100%;
    fill:#fff;
}
.bg-white {
    background: #fff;
    padding: 1rem!important;
    border-radius: 10px;
    padding-bottom: 4rem !important;
    z-index: 999999999999;
}

.theme-btn-thirteen:hover{
    background: #E52711 !important;
}


.login-icon-nav {
    max-width: 40px;
    width:100%;
    height: auto;
   }
   .dropdown-backdrop{
    position: static;
    z-index: 0;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    .login-icon-nav {
        max-width: 40px;
        width:100%;
        height: auto;
       }
       .mobile-center {
        text-align: center;
        justify-content: center;
    }
     
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .login-icon-nav {
        max-width: 40px;
        width:100%;
        height: auto;
       }
       .mobile-center {
        text-align: center;
        justify-content: center;
    }
     
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
   .login-icon-nav {
    max-width: 40px;
    width:100%;
    height: auto;
   }

   .mobile-center {
       text-align: center;
       justify-content: center;
    }
    
  }